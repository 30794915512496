import styled from 'styled-components';
const HolderStyleWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .holder_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .widget_title {
    margin-top: 100px;
    margin-bottom: 15px;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
  }
  .widget_title2 {
    margin-bottom: 15px;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
  }

  .holder_table {
    width: 80%;
    max-height: 1200px;
    overflow: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      background: rgba(44, 45, 61, 0.9);
      .table-header {
        background: #1e1f35;
        text-transform: uppercase;
        font-family: 'Russo One', sans-serif;

        th {
          background: #1e1f35;
          font-weight: 400;
          padding: 17px 30px;
          position: sticky; /* 헤더 고정 */
          top: 0; /* 테이블 상단에 고정 */
          z-index: 10; /* 스크롤 시 다른 요소보다 위로 표시 */
        }
      }

      td {
        position: relative;
        padding: 17px 30px;
        border-bottom: 1px solid #2e2f3c;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .holder_table {
      table {
        .table-header {
          td {
            padding: 17px 15px;
            font-size: 15px;
          }

          th {
            padding: 17px 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .holder_table {
      table {
        display: block;
        overflow-x: auto;

        td {
          font-size: 14px;
        }
      }
    }
  }
`;

export default HolderStyleWrapper;
