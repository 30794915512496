import { useEffect, useState } from 'react';
import HolderStyleWrapper from './index.style';

const HolderTable = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchHolders();
  }, []);

  async function fetchHolders() {
    try {
      const response = await fetch('https://api.metaf.global/holders');
      const data = await response.json();
      setList(data.holders);
    } catch (error) {
      console.log(error);
    }
  }
  const handleClick = (address) => {
    window.open(`https://tronscan.org/#/address/${address}`, '_blank');
  };
  return (
    <HolderStyleWrapper id="holder">
      <div className="holder_title">
        <h4 className="widget_title">METAS Holders</h4>
        <h4 className="widget_title2">
          A total of {list?.length} token holder accounts
        </h4>
      </div>
      <div className="holder_table">
        <table>
          <tbody>
            <tr className="table-header">
              <th>Rank</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Percentage</th>
              {/* <th>Latest Txn Time (UTC)</th> */}
            </tr>
            {list?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>

                <td
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={handleClick}
                >
                  {' '}
                  {item.address}
                </td>

                <td>
                  {((item.metas + item.lockOfMetas) / 1000000).toLocaleString()}
                </td>
                <td>
                  {(
                    ((item.metas + item.lockOfMetas) / 1000000 / 200000000) *
                    100
                  ).toFixed(2)}
                  %
                </td>
                {/* <td>{new Date().toUTCString()}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </HolderStyleWrapper>
  );
};

export default HolderTable;
