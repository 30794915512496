import GlobalStyles from 'assets/styles/GlobalStyles';
import Layout from 'components/layout';
import MetamaskModal from 'components/modal/metamaskModal/MetamaskModal';
import WalletModal from 'components/modal/walletModal/WalletModal';
import { Fragment } from 'react';
import Banner from 'sections/Banner/v1';
import Footer from 'sections/Footer/v1';
import Header from 'sections/Header/v1';
import HolderTable from 'sections/HolderPage/HolderTable';
import bannerBG from 'assets/images/bg/bg.jpg';
import { useModal } from 'utils/ModalContext';
import styled from 'styled-components';

export default function HolderPage() {
  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <PageWrapper>
      <Fragment>
        <Layout>
          <GlobalStyles />
          {walletModalvisibility && <WalletModal />}
          {metamaskModal && <MetamaskModal />}
          <Header />
          {/* <Banner /> */}
          <HolderTable />
        </Layout>
      </Fragment>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  background: url(${bannerBG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
`;
